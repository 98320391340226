import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

import { CloseOutlined } from '@ant-design/icons'

import gsap, { Power3 } from 'gsap/dist/gsap'

import './Disclaimer.scss'

const iconStyles = {
  color: '#4c6852',
  fontSize: 30,
  marginLeft: 25,
}

const EngDisclaimer = (
  <>
    <p>
      BECOME A CO-OWNER OF THE NEW PROJECT FROM SHELEST <a href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel">INVEST</a> IN SHELEST HUTIR.
      Mon is a shortened day, Tues and Wed are days off.
    </p>
  </>
)

const Disclaimer = () => {
  const { i18n } = useTranslation()
  const { push } = useHistory()

  const descriptions = {
    ukr: (
      <>
        <p>
          СТАНЬТЕ СПІВВЛАСНИКОМ НОВОГО ПРОЄКТУ ВІД SHELEST
          <br />
          <a href="https://www.shelest-hutir.com/?utm_source=site&utm_medium=hotel">ІНВЕСТУЙТЕ</a> В SHELEST HUTIR
        </p>
        
      </>
    ),
    en: EngDisclaimer,
  }

  const ref = useRef(null)

  const onPanelClick = () => {
    gsap.to(ref.current, {
      yPercent: 100,
      duration: 0.5,
      display: 'none',
    })

    sessionStorage.setItem('isPopupClicked', '1')
  }

  useEffect(() => {
    if (ref && ref.current) {
      gsap.from(ref.current, {
        yPercent: 100,
        duration: 0.5,
        delay: 0.5,
        ease: Power3.easeOut,
      })
    }
  }, [])

  return (
    <div className="pop-up-wrapper" ref={ref}>
      <p className="regular big">{descriptions[i18n.language]}</p>
      <CloseOutlined style={iconStyles} onClick={onPanelClick} />
    </div>
  )
}

export default Disclaimer

import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'

import useAbstractionMediaQueries from '../../hooks/useAbstractionMediaQueries'

import hairdryerIcon from '../../static/icons/hairdryer.svg'
import fireplaceIcon from '../../static/icons/camin.svg'
import twoDoubleBedsIcon from '../../static/icons/twobeds.svg'
import perfumesIcon from '../../static/icons/perfumes.svg'
import slippersIcon from '../../static/icons/homeshoe.svg'
import bathrobeIcon from '../../static/icons/closetclothes.svg'
import terraceIcon from '../../static/icons/terrace.svg'
import coffeeIcon from '../../static/icons/coffee.svg'
import showerIcon from '../../static/icons/shower.svg'
import airconditioningIcon from '../../static/icons/airconditioning.svg'
import deskIcon from '../../static/icons/desk.svg'
import forestviewIcon from '../../static/icons/forestview.svg'
import minibarIcon from '../../static/icons/minibar.svg'
import safeIcon from '../../static/icons/safe.svg'
import tvIcon from '../../static/icons/tv.svg'
import wardrobeIcon from '../../static/icons/wardrobe.svg'
import poolViewIcon from '../../static/icons/pool_view.svg'
import ironIcon from '../../static/icons/iron.svg'
import waterIcon from '../../static/icons/water.svg'
// import forestIcon from '../../static/icons/forest.svg'
import gobletIcon from '../../static/icons/goblet.svg'
import kitchenIcon from '../../static/icons/kitchen.svg'
import kettleIcon from '../../static/icons/kettle.svg'
import bathtubIcon from '../../static/icons/bathtub.svg'
import poolIcon from '../../static/icons/pool.svg'
import refrigeratorIcon from '../../static/icons/refrigerator.svg'
import dishesIcon from '../../static/icons/dishes.svg'

import './AmenitiesStyles.scss'

const Amenities = ({ amenities, renderType, hasTooltip }) => {
  const icons = {
    hairdryer: hairdryerIcon,
    beauty_aids: perfumesIcon,
    slippers: slippersIcon,
    bathrobe: bathrobeIcon,
    terrace: terraceIcon,
    two_double_beds: twoDoubleBedsIcon,
    fireplace: fireplaceIcon,
    coffee_machine: coffeeIcon,
    shower: showerIcon,
    air_conditioning: airconditioningIcon,
    desk: deskIcon,
    forest_view: forestviewIcon,
    minibar: minibarIcon,
    safe: safeIcon,
    smart_tv: tvIcon,
    wardrobe: wardrobeIcon,
    pool_view: poolViewIcon,
    iron: ironIcon,
    drinking_water: waterIcon,
    // forest_view: forestIcon,
    kitchen: kitchenIcon,
    kettle: kettleIcon,
    bathtub: bathtubIcon,
    pool: poolIcon,
    refrigerator: refrigeratorIcon,
    cookware: dishesIcon,
  }

  const { isTablet } = useAbstractionMediaQueries()
  const { t } = useTranslation()

  const renderAmenitiesGrid = () => {
    return amenities.map(({ name, kind }) => {
      const isTooltip = name === 'minibar' || name === 'міні-бар'

      return icons[kind] ? (
        <li key={kind} className="amenities-grid-item">
          <img src={icons[kind]} />
          <span>{name}</span>
          {isTooltip ? (
            <Tooltip title={t('Rooms.PaidSeparately')}>
              <QuestionCircleOutlined style={{ marginLeft: 5 }} />
            </Tooltip>
          ) : null}
        </li>
      ) : null
    })
  }

  const renderAmenitiesList = () => {
    return amenities.map(({ name, kind }) => {
      const isTooltip = name === 'minibar' || name === 'міні-бар'

      return icons[kind] ? (
        <Tooltip
          trigger={isTablet ? 'click' : 'hover'}
          title={
            <>
              {isTooltip ? <>{t('Rooms.PaidSeparately')}: </> : null}

              {name}
            </>
          }
        >
          <li key={kind} className="amenities-list-item">
            <div className="amenity-icon-block">
              <img src={icons[kind]} />
            </div>
          </li>
        </Tooltip>
      ) : null
    })
  }

  const renderedAmenitiesGrid = renderAmenitiesGrid()
  const renderedAmenitiesList = renderAmenitiesList()

  const renderTypes = {
    rooms: () => {
      return (
        <>
          <p className="amenities-heading">{t('Rooms.SecondParagraph')}</p>
          <ul className="amenities-grid">{renderedAmenitiesGrid}</ul>
        </>
      )
    },
    booking: () => {
      return <ul className="amenities-list">{renderedAmenitiesList}</ul>
    },
  }

  if (isEmpty(amenities)) {
    return null
  }

  return <div className="amenities">{renderTypes[renderType]()}</div>
}

Amenities.defaultProps = {
  amenities: [],

  renderType: null,

  hasTooltip: false,
}

Amenities.propTypes = {
  amenities: PropTypes.array,

  renderType: PropTypes.string,

  hasTooltip: PropTypes.bool,
}

export default Amenities
